import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`An ECDN server’s software configuration.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> ServerConfiguration <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># This configuration's status on ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">status</GQLName>: <GQLLink href="/ecdn-api-enums/ConfigurationStatus" mdxType="GQLLink">ConfigurationStatus</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Selected mode to apply this configuration to an ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">mode</GQLName>: <GQLLink href="/ecdn-api-enums/ConfigurationMode" mdxType="GQLLink">ConfigurationMode</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Whether content delivery is enabled or not.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">contentDeliveryEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Network interfaces of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Currently it's always a single interface with name of eth0.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">interfaces</GQLName>: [<GQLLink href="/ecdn-api-objects/Interface" mdxType="GQLLink">Interface</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># DNS servers used for name resolutions.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">dnsServers</GQLName>: [<GQLLink href="/ecdn-api-scalars/ServerAddress" mdxType="GQLLink">ServerAddress</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Address of NTP servers used by ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Null in case ECDN server reported invalid (malformed) NTP server addresses.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">ntpServers</GQLName>: [<GQLLink href="/ecdn-api-scalars/ServerAddress" mdxType="GQLLink">ServerAddress</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Is remote assistance enabled on server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">remoteAssistanceEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Is SNMP polling enabled on server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">snmpEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Community string of SNMP v2c. Null if SNMP polling is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">snmpCommunityString</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Is log forwarding enabled on server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">logForwardEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Log collector's address. Null if log forwarding is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">logCollector</GQLName>: <GQLLink href="/ecdn-api-scalars/TransportAddress" mdxType="GQLLink">TransportAddress</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Config CD's URL. Null if mode is remote configuration.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returned URL expires in 24 hours.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">cdURL</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Image URL for the appropriate hypervisor.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Null if server configuration was created through legacy ECDN API (or legacy portal).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Returned URL expires in 24 hours.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">imageURL</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`status: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/ConfigurationStatus"
      }}>{`ConfigurationStatus`}</a>{`!`}</h3>
    <p>{`This configuration’s status on ECDN server.`}</p>
    <h3>{`mode: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/ConfigurationMode"
      }}>{`ConfigurationMode`}</a>{`!`}</h3>
    <p>{`Selected mode to apply this configuration to an ECDN server.`}</p>
    <h3>{`contentDeliveryEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Whether content delivery is enabled or not.`}</p>
    <h3>{`interfaces: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Interface"
      }}>{`Interface`}</a>{`!]!`}</h3>
    <p>{`Network interfaces of ECDN server.
Currently it’s always a single interface with name of eth0.`}</p>
    <h3>{`dnsServers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ServerAddress"
      }}>{`ServerAddress`}</a>{`!]!`}</h3>
    <p>{`DNS servers used for name resolutions.`}</p>
    <h3>{`ntpServers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ServerAddress"
      }}>{`ServerAddress`}</a>{`!]`}</h3>
    <p>{`Address of NTP servers used by ECDN server.
Null in case ECDN server reported invalid (malformed) NTP server addresses.`}</p>
    <h3>{`remoteAssistanceEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Is remote assistance enabled on server.`}</p>
    <h3>{`snmpEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Is SNMP polling enabled on server.`}</p>
    <h3>{`snmpCommunityString: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a></h3>
    <p>{`Community string of SNMP v2c. Null if SNMP polling is disabled.`}</p>
    <h3>{`logForwardEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a>{`!`}</h3>
    <p>{`Is log forwarding enabled on server.`}</p>
    <h3>{`logCollector: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/TransportAddress"
      }}>{`TransportAddress`}</a></h3>
    <p>{`Log collector’s address. Null if log forwarding is disabled.`}</p>
    <h3>{`cdURL: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a></h3>
    <p>{`Config CD’s URL. Null if mode is remote configuration.
Returned URL expires in 24 hours.`}</p>
    <h3>{`imageURL: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a></h3>
    <p>{`Image URL for the appropriate hypervisor.
Null if server configuration was created through legacy ECDN API (or legacy portal).
Returned URL expires in 24 hours.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      